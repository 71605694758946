<template>
  <div>
    <div class="page_title">
      <div class="title">
        <i class="el-icon-s-promotion"> </i>
        <span>在线预定</span>
      </div>
    </div>
    <div class="page_info">
      <div class="page_title info_box">
        <div class="left">
          <img :src="$global.regexImg(info.cover)"
               alt="">
        </div>
        <div class="right">
          <div>{{info.obj.title}}</div>
          <div>
            <el-rate v-model="info.star_num"
                     void-color='#fff'
                     disabled></el-rate>
          </div>
          <div>{{info.address}}</div>
        </div>
      </div>
    </div>
    <!-- room -->
    <div class="page_title"
         style='padding-bottom:40px'>
      <div class="room_row">
        <div class="left">
          <img :src="$global.regexImg(info.obj.cover)"
               alt="">
          <p>{{info.obj.name}}</p>
          <div>
            <span> {{info.obj.bed_type}}</span>
            <span>{{info.obj.wifi == 1 ?'有wifi' :'无wifi'}}</span>
            <span>{{info.obj.window == 1 ?'有窗户' :'无有窗户'}}</span>
            <span>面积{{info.obj.area}}m2</span>
          </div>
        </div>
      </div>
    </div>
    <!-- info -->
    <div class="page_title">
      <div class="row_box">
        <div class="left">入住时间：</div>
        <div class="right right1">
          <p>{{info.startTime}}</p>
          <span>~</span>
          <p>{{info.endTime}}</p>
        </div>
      </div>
    </div>
    <div class="page_title">
      <div class="row_box">
        <div class="left">入住人：</div>
        <div class="right ">
          <el-input v-model="userObj.name"
                    class="input"
                    maxlength='20'
                    placeholder="请填写入住人姓名"></el-input>
        </div>
      </div>
    </div>
    <div class="page_title">
      <div class="row_box">
        <div class="left">联系电话：</div>
        <div class="right">
          <el-input v-model="userObj.phone"
                    maxlength='11'
                    class="input"
                    placeholder="请填写联系人电话"></el-input>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="page_title">
      <div class="money_box">
        <div class="row">
          <p>单价：</p>
          <p class="money"><span>¥</span>{{info.obj.price}}</p>
        </div>
        <div class="row">
          <p>总价：</p>
          <p class="money"><span>¥</span>{{money}}</p>
        </div>
        <!-- class="ButtonAnimation" -->
        <p>
          <el-button class="button "
                     @click='onSave()'>立即支付</el-button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { set_roomOrder } from '@/utils/Api/meeting'
export default {
  components: {},
  props: [],
  data () {
    return {
      hotelId: null,
      meetingId: null,
      userObj: {
        name: '',
        phone: ''
      },
      info: {},
      orderInfo: {},
      payLoading: false,
      money: '',
      duan_type: 2,
    }
  },
  methods: {
    async onSave () {
      if (!this.userObj.name) return this.$message.warning('请填写入住人姓名')
      if (!/^([\u4E00-\u9FA5A-Za-z\d]{2,20})$/.test(this.userObj.name)) {
        return this.$message.warning('请输入2-20位汉字、字母或数字的姓名');
      }
      if (!this.userObj.phone) return this.$message.warning('请填写联系人电话')
      if (/^1[3-9]\d{9}$/.test(this.userObj.phone) == false) return this.$message.warning('联系人电话输入错误');
      var res = await set_roomOrder({
        start_date: this.info.startTime,
        room_id: this.info.id,
        type: 2, //1支付宝2微信
        name: this.userObj.name,
        phone: this.userObj.phone,
        day_num: this.info.day,
        duan_type: 2
      })
      if (res) {
        this.$router.push({ path: '/onlinePayment', query: { type: 2, orderId: res } })
      } else {
        this.$message.warning('生成订单失败')
      }
    },
  },
  created () {
    this.hotelId = this.$route.query.hotelId;
    this.meetingId = this.$route.query.meetingId;
    this.info = JSON.parse(decodeURIComponent(this.$route.query.info));
    this.info.cover = this.$route.query.cover
    this.money = Number(this.info.obj.price) * Number(this.info.day);
  },
  mounted () { },
  destroyed () { },
  computed: {},
  watch: {}
}
</script>

<style lang="scss" scoped>
.page_title {
  width: 1200px;
  margin: 0 auto;
}
.pagination {
  margin: 20px 0;
}
.title {
  padding: 20px 0;
  text-align: left;
  color: #6c6c6c;
  line-height: 30px;
  span {
    margin-left: 10px;
  }
}
.page_info {
  background-color: #fff;
  width: 100%;
  .info_box {
    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    .left {
      width: 340px;
      height: 210px;
      overflow: hidden;
      img {
        width: 340px;
        height: 210px;
        &:hover {
          transform: scale(1.1);
          transition: all 0.3s;
        }
      }
    }
    .right {
      width: calc(100% - 400px);
      padding: 20px 0;
      box-sizing: border-box;
      > div {
        text-align: left;
      }
      > div:nth-child(1) {
        font-size: 20px;
        line-height: 28px;
        color: #030303;
        margin-bottom: 20px;
      }
      > div:nth-child(3) {
        margin-top: 66px;
        font-size: 14px;
        line-height: 20px;
        color: #999999;
      }
    }
  }
}
.room_row {
  cursor: pointer;
  width: 1200px;
  height: 96px;
  background: #ffffff;
  margin: 34px 0 24px;
  .left {
    padding: 8px 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    img {
      align-self: flex-start;
      width: 80px;
      height: 80px;
      border-radius: 10px;
    }
    > p {
      text-align: left;
      font-weight: 700;
      margin-left: 16px;
      width: 200px;
      font-size: 14px;
      line-height: 20px;
      color: #333;
      display: block;
      overflow: hidden;
      text-overflow: ellispsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    > div {
      padding-left: 16px;
      box-sizing: border-box;
      width: calc(100% - 310px);
      font-size: 14px;
      line-height: 20px;
      color: #999;
      span {
        margin-right: 10px;
      }
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.row_box {
  width: 1200px;
  height: 52px;
  background: #ffffff;
  padding: 0 20px;
  box-sizing: border-box;
  font-size: 14px;
  display: flex;
  color: #333;
  margin-bottom: 10px;
  align-items: center;
  .left {
    width: 80px;
    text-align: right;
  }
  .right {
    width: calc(100% - 80px);
    text-align: center;
    display: flex;
    align-self: center;
    justify-content: flex-start;
    p {
      display: block;
      width: 116px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: #f5f5f5;
      border-radius: 6px;
      font-size: 14px;
      color: #999;
    }
    span {
      color: #999;
      margin: 0 20px;
      font-size: 20px;
    }
  }
}
/deep/ .el-input__inner {
  border: 0;
}
.money_box {
  margin-top: 55px;
  padding-bottom: 110px;
  text-align: left;
  padding-left: 20px;
  box-sizing: border-box;
  > p {
    padding: 0;
    position: relative;
    overflow: hidden;
    margin-top: 50px;
    width: 132px;
  }
  .button {
    width: 132px;
    font-size: 16px;
    color: #fff;
    height: 40px;
    background: #fd5151;
    border-radius: 4px;
    border: 0;
  }
  .row {
    margin-bottom: 20px;
    display: flex;
    align-self: center;
    > p:nth-chid(1) {
      color: #333;
      width: 100px;
    }
    .money {
      color: #ff5151;
      font-weight: 700;
      font-size: 19px;
      span {
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
}
</style>
